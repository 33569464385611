import { Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "../Box";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { TableFilters } from "./TableFilters";
import useViewport from "../../hooks/useViewport";
import FiltersMobile from "./FiltersMobile";
import { TypesOptionsFields } from "../../routes/Services/utils/intefaces/TypesOptionsFIelds";
import { SortFilterLabel } from "./interfaces";
import { useGetServices } from "../../hooks/useGetServices";
import sendErrorToast from "../../utils/sendErrorToast";
import useGetThemePath from "../../hooks/useGetThemePath";

interface TableServicesProps {
  options: Array<TypesOptionsFields>;
  handleChangeTypeOfView: (v: number) => void;
  children: React.ReactElement;
  setSelectedTypeServiceFilter: (val: string) => void;
  setSortFilter: (v: SortFilterLabel | null) => void;
  sortFilter: SortFilterLabel | null;
  handleInputChange?: (val: string) => void;
  isMyTabsActive: boolean;
  activeServiceFilter: string | null;
}

const TableServices = ({
  children,
  options,
  handleChangeTypeOfView,
  setSelectedTypeServiceFilter,
  setSortFilter,
  sortFilter,
  handleInputChange,
  isMyTabsActive,
  activeServiceFilter = "All Services",
}: TableServicesProps) => {
  const { data: services } = useGetServices({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "Sorry, there was a problem retrieving the available services"
      ),
  });

  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const [tabOptions, setTabOptions] = useState<
    {
      label: string;
      isActive: boolean;
      display: boolean;
    }[]
  >([]);

  const { isMobile } = useViewport();

  const handleChangeActiveTab = (idx: number) => {
    const changedTab = tabOptions.map((v, i) => {
      return {
        ...v,
        isActive: i === idx,
      };
    });

    setSelectedTypeServiceFilter(
      changedTab.find((b) => b?.isActive === true)?.label as string
    );
    setTabOptions(changedTab);
  };

  useEffect(() => {
    const mapped =
      services?.data?.servicetype?.map((svc) => {
        return {
          label: svc.name,
          isActive: activeServiceFilter === svc.name,
          display: svc.display ?? false,
        };
      }) ?? [];

    const optionsList = [
      {
        label: "All Services",
        isActive:
          activeServiceFilter === "All Services" || !activeServiceFilter,
        display: true,
      },
      ...mapped,
    ];

    const filterOptions = optionsList.filter(
      (option) => option.display === true
    );

    setTabOptions(filterOptions);
  }, [services]);

  return (
    <Box hasBoxShadow sx={{ padding: 0 }}>
      {!isMobile && isMyTabsActive && (
        <Stack
          flexWrap="wrap"
          marginBottom={getREMFromPX(theme.spacing * 8)}
          flexDirection="row"
          alignItems="center"
          gap={getREMFromPX(39)}
          data-cy="servicesNavButtons"
          sx={{
            borderBottom: "1.5px solid #E8E8E8",
          }}
        >
          {tabOptions.map(({ label, isActive }, idx) => (
            <Button
              key={`${label}+${idx}`}
              sx={{
                fontSize: getREMFromPX(14),
                fontWeight: isActive
                  ? theme.fonts.weights.mediumBold
                  : theme.fonts.weights.normal,
                fontFamily: "Inter",
                cursor: "pointer",
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                textTransform: "none",
                lineHeight: 1.5,
                "&:hover": {
                  color: colors.form.primary,
                  backgroundColor: "transparent",
                },
                color: isActive
                  ? colors.textPrimary.heading
                  : colors.table.secondary,
                borderBottom: isActive
                  ? "2px solid #416661"
                  : "2px solid  transparent",
                borderRadius: 0,
              }}
              onClick={() => handleChangeActiveTab(idx)}
              data-cy={`navButton${label.replace(/\s+/g, "")}`}
              aria-pressed={isActive}
            >
              {label}
            </Button>
          ))}
        </Stack>
      )}
      {!isMobile && isMyTabsActive ? (
        <TableFilters
          options={options}
          handleChangeTypeOfView={handleChangeTypeOfView}
          setSortFilter={setSortFilter}
          sortFilter={sortFilter}
          handleInputChange={handleInputChange}
        />
      ) : (
        <FiltersMobile
          options={options}
          tabOptions={tabOptions}
          handleChangeActiveTab={handleChangeActiveTab}
          setSortFilter={setSortFilter}
          sortFilter={sortFilter}
          handleInputChange={handleInputChange}
          isMyTabsActive={isMyTabsActive}
          handleChangeTypeOfView={handleChangeTypeOfView}
        />
      )}
      {children}
    </Box>
  );
};

export default TableServices;
