import { theme, ThemePaths } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const introAlertStyles = {
  margin: `${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(
    theme.spacing * 2
  )} 0 ${getREMFromPX(theme.spacing * 2)}`,
  padding: getREMFromPX(theme.spacing * 6),
};

export const getWifiStyles = (themePath: ThemePaths = "default") => ({
  fontSize: getREMFromPX(32),
  color: theme[themePath].colors.primary[500],
});

export const spinnerContainerStyles = {
  margin: "0 !important",
};

export const buttonStyles = {
  width: "100%",
};

export const findAPlanButtonStyles = {
  marginTop: getREMFromPX(theme.spacing * 6),
};

export const getAddressArrowStyles = (themePath: ThemePaths = "default") => ({
  fill: theme[themePath].colors.textPrimary.primary,
  alignSelf: "flex-end",
});

export const buttonStylesN = {
  border: 0,
  background: "none",
};

export const buttonWeb = {
  width: "74px",
  height: "28px",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
  borderRadius: "13px",
  backgroundColor: "#d7f1da",
  border: "none",
  cursor: "pointer",
} as React.CSSProperties;

export const buttonWebText = {
  width: "26px",
  height: "12px",
  flexGrow: 0,
  fontFamily: "Montserrat",
  fontSize: "10px",
  fontWeight: "600",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: "darkgreen",
} as React.CSSProperties;
