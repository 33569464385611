import { Backdrop, CircularProgress, Stack, Grid } from "@mui/material";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { spinnerContainerStyles } from "./styles";
import sendErrorToast from "../../utils/sendErrorToast";
import React, { useContext, useEffect } from "react";
import { useGetServices } from "../../hooks/useGetServices";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import Warning from "../../components/Warning";
import NavContainer from "../../components/NavContainer/NavContainer";
import TotalBill from "./TotalBill";
import MyDevice from "./MyDevice";
import AllServices from "./AllServices";
import HeaderContent from "./HeaderContent";
import MyServicesCard from "../../components/MyServicesCard";
import { useGetConfig } from "../../hooks/useGetConfig";
import useViewport from "../../hooks/useViewport";
import useBillingConfig from "../../hooks/useBillingConfig";

const Dashboard = ({ children }: { children?: React.ReactElement }) => {
  const {
    userInfo,
    selectedDeviceId,
    currentDevice,
    isLoading: isContextLoading,
  } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;
  const { isMobile } = useViewport();

  const { data: config } = useGetConfig();
  const { data: billingConfig } = useBillingConfig();

  const showBilling =
    config?.data?.config?.enabled_features?.BILLING?.enabled &&
    !billingConfig?.data?.billing?.config?.foxy_cart?.cart_update_url;

  const { data: services, isLoading: areServicesLoading } = useGetServices({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "Sorry, there was a problem retrieving the available services"
      ),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo?.data?.user?.force_password) {
      navigate(routes.updatePassword.path);
    }
  }, [userInfo, navigate]);

  const isLoading = areServicesLoading || isContextLoading;

  return (
    <NavContainer title="Dashboard" headerContent={<HeaderContent />}>
      {children || (
        <>
          <div style={spinnerContainerStyles}>
            <Backdrop open={isLoading}>
              <CircularProgress data-testid="progressSpinner" color="inherit" />
            </Backdrop>
          </div>
          {!isLoading && (
            <>
              <Grid
                container
                flexWrap={{
                  xs: "wrap",
                  md: "nowrap",
                }}
                spacing={3}
              >
                <Grid
                  minWidth={{
                    xs: "none",
                    md: 364,
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  xl={3}
                >
                  <Stack spacing={3}>
                    {showBilling && <TotalBill />}
                    {isMobile ? (
                      currentDevice && <MyDevice device={currentDevice} />
                    ) : (
                      <MyServicesCard />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={9}>
                  <Stack spacing={3}>
                    {isMobile ? (
                      <MyServicesCard />
                    ) : (
                      currentDevice && <MyDevice device={currentDevice} />
                    )}
                    <AllServices services={services} />
                  </Stack>
                </Grid>
              </Grid>

              <Stack
                spacing={getREMFromPX(theme.spacing * 8)}
                style={{ width: "100%", paddingLeft: "2px" }}
              >
                <Stack
                  spacing={getREMFromPX(theme.spacing * 8)}
                  padding={`${getREMFromPX(theme.spacing * 6)} ${getREMFromPX(
                    theme.spacing * 6
                  )} 0 ${getREMFromPX(theme.spacing * 8)}`}
                >
                  {!selectedDeviceId && !isLoading && (
                    <Warning message="No device is associated with your account." />
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </>
      )}
    </NavContainer>
  );
};
export default Dashboard;
