import {
  Backdrop,
  CircularProgress,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type } from "../../ForgotPassword";
import useViewport from "../../../../hooks/useViewport";
import EntryPointLink from "../../../../components/EntryPointLink/EntryPointLink";
import Button from "../../../../components/Button";
import sendErrorToast from "../../../../utils/sendErrorToast";
import { useGetUsersSecurityQuestions } from "../../../../hooks/useGetSecurityQuestions";
import { useEffect } from "react";
import useGetThemePath from "../../../../hooks/useGetThemePath";

interface Step2Props {
  changeStep: (args: Step2Type) => void;
  goBackOneStep: () => void;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
  };
  setError?: (args: { message: string; step: number }) => void;
  error?: string;
}

const Step2 = ({ changeStep, goBackOneStep, previousValues }: Step2Props) => {
  const { isMobile } = useViewport(600);
  const navigate = useNavigate();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const {
    data: userSecurityQuestions,
    isLoading: isLoadingUserSecurityQuestions,
  } = useGetUsersSecurityQuestions(previousValues.step1.userName, undefined, {
    onError: (e) => {
      const message = e.message?.includes("User does not exist")
        ? "Username or Email is invalid"
        : "There was an error fetching the security questions, please try again";
      sendErrorToast(message);
      goBackOneStep();
    },
    refetchOnWindowFocus: false,
  });

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<Step2Type>({
    defaultValues: {
      questions: previousValues.step2.questions,
    },
    mode: "onChange",
  });

  const questions = watch("questions");
  const disableSubmitButton = !isValid;

  useEffect(() => {
    if (userSecurityQuestions) {
      userSecurityQuestions.forEach((question, idx) => {
        setValue(`questions.${idx}.question`, question.question);
        setValue(`questions.${idx}.id`, question.id);
      });
    }
  }, [userSecurityQuestions, setValue]);

  const onSubmit = () => {
    const selectedOptions = { questions };
    changeStep(selectedOptions);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            flex: 1,
            width: "100%",
            maxWidth: "530px",
            padding: "0 15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              width: "100%",
              fontFamily: "Inter",
              fontSize: isMobile ? getREMFromPX(14) : getREMFromPX(20),
              fontWeight: 600,
              marginBottom: isMobile ? "8px" : "16px",
              color: colors.textPrimary.primary,
            }}
          >
            Security Questions
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(16),
              fontWeight: 500,
              lineHeight: isMobile ? 1.5 : 1.56,
              marginBottom: isMobile ? "24px" : "36px",
              textAlign: "left",
            }}
          >
            To ensure it's really you, please provide answers to the security
            questions associated with the account:{" "}
            <span style={{ fontWeight: "900" }}>
              {previousValues.step1.userName}
            </span>
            .
            <br />
            Answers are case sensitive.
          </Typography>
          <Stack
            sx={{
              width: "100%",
              maxWidth: "530px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {!userSecurityQuestions
              ? null
              : userSecurityQuestions.map((questionData, idx) => (
                  <Stack sx={{ width: "100%", mb: "20px" }}>
                    <Label
                      htmlFor={`question${idx}Option`}
                      style={{
                        fontFamily: "Inter",
                        fontSize: isMobile
                          ? getREMFromPX(12)
                          : getREMFromPX(16),
                        fontWeight: "500",
                        textAlign: "left",
                        marginBottom: "20px",
                        color: colors.form.primary,
                      }}
                    >
                      {questionData.question}
                    </Label>
                    <Input
                      data-testid={"answer" + idx}
                      placeholder="Answer"
                      {...register(`questions.${idx}.answer`, {
                        required: true,
                      })}
                    />
                  </Stack>
                ))}
          </Stack>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <>
              <Button
                text="Confirm"
                mode="default"
                type="submit"
                disabled={disableSubmitButton}
                sx={{
                  width: "100%",
                  marginBottom: "30px",
                  borderRadius: `${isMobile ? "9.3px" : "15px"}`,
                }}
              />
              <Button
                text="Cancel"
                mode="secondary"
                onClick={() => navigate("/")}
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                  borderRadius: `${isMobile ? "9.3px" : "15px"}`,
                }}
              />
            </>
          </Box>
          <div style={{ marginTop: isMobile ? "16px" : "40px" }}>
            <EntryPointLink />
          </div>
        </Box>
      </form>
      <Backdrop open={isLoadingUserSecurityQuestions}>
        <CircularProgress color="inherit" data-testid="progressSpinner" />
      </Backdrop>
    </>
  );
};
export default Step2;
