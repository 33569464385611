import { Box, styled } from "@mui/material";
import TileWrapper from "./TileWrapper";
import { getThemePath } from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

const themePath = getThemePath();
const colors = theme[themePath].colors;
export interface CurrentSubscription {
  serviceType: string;
  serviceName: string;
  providerName: string;
  serviceLogo: string;
  devicePort: string;
  deviceName: string;
  price: string;
}

interface MyServicesTileProps {
  subscription: CurrentSubscription;
}

const OuterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px 8px 16px;
`;

const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftItem = styled("div")``;

const RightItem = styled("div")`
  margin-left: auto;
  flex-shrink: 0;
`;

const DeviceBox = styled("div")`
  border-radius: 0.625rem;
  border: 1px solid var(--light-grey, #efefef);
  background: var(--white, #fff);
  padding: 0.1rem 0.25rem 0.1rem 0.25rem;
`;

const Header = styled("div")`
  color: #0e251b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ServiceName = styled("div")`
  color: #0e251b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
`;

const PortName = styled("span")`
  color: var(--dark-green-font-color, #253331);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9375rem;
`;

const DeviceName = styled("div")`
  color: #0c263d;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9375rem;
  text-align: left;
`;

const OnButton = () => (
  <span style={{ marginLeft: "6px" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
    >
      <circle cx="5.5" cy="5.5" r="5.5" fill={colors.icons.default.fillColor} />
    </svg>
  </span>
);

const MyServicesTile = ({ subscription }: MyServicesTileProps) => {
  return (
    <TileWrapper>
      <Box
        sx={{
          fontFamily: "Inter",
          background: "#E8E8E8",
          textAlign: "center",
          padding: "8px 16px",
          color: "#253331",
          fontWeight: 700,
          textTransform: "uppercase",
        }}
      >
        {subscription.serviceType}
      </Box>
      <OuterContainer>
        <Container>
          <LeftItem>
            <Header>{subscription.providerName}</Header>
          </LeftItem>
          <RightItem>
            <DeviceName sx={{ textAlign: "right", fontWeight: "700" }}>
              {subscription.price} <br /> / Month
            </DeviceName>
          </RightItem>
        </Container>
        <Container
          sx={{
            alignItems: "end",
          }}
        >
          <LeftItem>
            <ServiceName>{subscription.serviceName}</ServiceName>
            <DeviceName>{subscription.deviceName}</DeviceName>
          </LeftItem>
          <RightItem>
            <DeviceBox>
              <PortName>{subscription.devicePort}</PortName>
              <OnButton />
            </DeviceBox>
          </RightItem>
        </Container>
      </OuterContainer>
    </TileWrapper>
  );
};

export default MyServicesTile;
