import { useContext, useMemo } from "react";
import {
  Box,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import { ExpandMore } from "@mui/icons-material";

const SelectDevice = ({ children, ...props }: MenuItemProps) => {
  const { devices, currentDevice, setCurrentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const devicePhysicalLocations = useMemo(() => {
    const shouldAddDeviceNumber = devices?.length > 1;

    return devices
      ?.map((device, idx) => {
        if (
          device?.Physicallocation &&
          device?.Physicallocation?.street &&
          device?.Physicallocation?.house_number
        ) {
          return (
            <MenuItem
              key={device.id}
              value={device.id}
              data-cy={`deviceSelect-${device.id}`}
            >
              {`${shouldAddDeviceNumber ? `Device ${idx + 1} - ` : ""}
                ${device.Physicallocation.house_number} 
                ${device.Physicallocation.pre_directional} 
                ${device.Physicallocation.street} 
                ${device.Physicallocation.post_directional} 
                ${device.Physicallocation.apartment_number}`}
            </MenuItem>
          );
        }

        return (
          <MenuItem
            key={device.id}
            value={device.id}
            data-cy={`deviceSelect-${device.id}`}
          >
            {`${shouldAddDeviceNumber ? `Device ${idx + 1} - ` : ""}${
              device.serial_number
            }`}
          </MenuItem>
        );
      })
      .filter(Boolean);
  }, [devices]);

  if (devicePhysicalLocations?.length < 1) {
    return (
      <Box sx={{ fontWeight: 600, color: "red", fontFamily: "Inter" }}>
        No Address Found
      </Box>
    );
  }

  if (devicePhysicalLocations?.length === 1) {
    return (
      <Box
        sx={{
          fontWeight: 600,
          fontFamily: "Inter",
          marginLeft: "-10px !important",
        }}
      >
        {devicePhysicalLocations}
      </Box>
    );
  }

  return (
    <Select
      sx={{
        fontFamily: "Inter",
        ...props.sx,
      }}
      value={currentDevice.id}
      onChange={(event: SelectChangeEvent) => {
        const newDevice = devices.find(
          (device) => device.id === event.target.value
        );
        if (newDevice) {
          setCurrentDevice(newDevice);
        }
      }}
      IconComponent={ExpandMore}
      data-cy="myDeviceSelectInput"
    >
      {devicePhysicalLocations}
    </Select>
  );
};
export default SelectDevice;
