import { Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Button from "../Button";
import useAchForm from "../../hooks/useAchForm";
import { useGetConfig } from "../../hooks/useGetConfig";
import { ErrorType } from "../BillingProvider";
import useCreditCardForm from "../../hooks/useCreditCardForm";
import getREMFromPX from "../../utils/getREMFromPX";
import useViewport from "../../hooks/useViewport";
import useGetThemePath from "../../hooks/useGetThemePath";
import Loader from "../Loader";

type PaymentFormProps = {
  paymentType: string;
  accountId: string;
  errors?: ErrorType[];
  setConfirmSwitch: React.Dispatch<React.SetStateAction<Boolean>>;
  setVerificationRequired: React.Dispatch<
    React.SetStateAction<Boolean | undefined>
  >;
  setBillingErrors: React.Dispatch<
    React.SetStateAction<ErrorType[] | undefined>
  >;
  nextStep?: () => void;
};

const PaymentFormWrapper = ({
  paymentType,
  setConfirmSwitch,
  setVerificationRequired,
  setBillingErrors,
  nextStep,
}: PaymentFormProps) => {
  const themePath = useGetThemePath();
  const { colors } = theme[themePath];

  const { data: config, isLoading: isConfigLoading } = useGetConfig();
  const { isMobile } = useViewport();
  const showACH = config?.data?.config?.enabled_features?.ACH_PAYMENT?.enabled;

  const isAch = paymentType === "ACH" && showACH;

  // if successful, reset verification required and errors
  const onSuccess = (required = false, errors = []) => {
    setVerificationRequired(required);
    setBillingErrors(errors);
  };

  const {
    form: achForm,
    fields: achFields,
    getMandate,
    submit: onSubmitAch,
    isLoading: isLoadingAch,
  } = useAchForm((response: any) => {
    // when successful reset values (step increments automatically upon setting verificationRequired true)
    const r = response?.data?.billing;
    onSuccess(r?.ach_verification_required, r?.errors);
  });

  const {
    form: ccForm,
    fields: ccFields,
    submit: onSubmitCreditCard,
    errorMessage,
    isLoading: isLoadingCreditCard,
    setError: setCCError,
  } = useCreditCardForm((response: any) => {
    if (response.message?.includes("Your card number is incorrect")) {
      // catch card number error
      ccForm.setError("cardNumber", {
        message: "This does not appear to be a valid credit card.",
      });
    } else if (response.message?.includes("expired")) {
      // catch expiration date error
      ccForm.setError("expirationDate", {
        message:
          "This date is invalid. Verify expiration date and submit again.",
      });
    } else if (response.message) {
      // catch any other generic failure
      setCCError(
        response.message.replace(
          "Error creating Stripe Customer Payment Method: ",
          ""
        )
      );
    } else {
      // when successful reset values and go to next step
      onSuccess();
      nextStep?.();
    }
  });

  // button is disabled when loading or form is invalid
  const isDisabled =
    isLoadingCreditCard ||
    isLoadingAch ||
    (isAch && !achForm.formState.isValid) ||
    (!isAch && !ccForm.formState.isValid);

  if (isLoadingAch || isLoadingCreditCard || isConfigLoading) {
    return (
      <Box
        sx={{
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: getREMFromPX(20),
          fontWeight: 500,
          color: colors.textPrimary.heading,
        }}
      >
        {errorMessage ? "Payment Error" : `${paymentType} Payment`}
      </Typography>
      {isAch ? achFields : ccFields}
      {isAch && getMandate()}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "52px",
        }}
      >
        {showACH && (
          <Typography
            onClick={() => {
              setConfirmSwitch(true);
              setCCError("");
            }}
            sx={{
              cursor: "pointer",
              fontFamily: "Inter",
              fontSize: getREMFromPX(isMobile ? 10 : 20),
              fontWeight: 600,
              color: colors.links.teal,
              textDecoration: "underline",
            }}
          >
            Switch to {isAch ? "Credit Card Payment" : "ACH Payment"}
          </Typography>
        )}
        <Button
          text="Save"
          mode="default"
          data-testid="submit_button"
          sx={{ padding: "16px 67px" }}
          onClick={isAch ? onSubmitAch : onSubmitCreditCard}
          disabled={isDisabled}
          isLoading={isLoadingCreditCard || isLoadingAch}
        />
      </Box>
    </>
  );
};

export default PaymentFormWrapper;
