import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { AccountQueryList } from "../api/interfaces/Account";
import fetcher from "../utils/fetchWrapper";

interface AccountCreateBody {
  user: {
    username: string;
    password: string;
    security_answer1?: string;
    security_answer2?: string;
    security_question1?: string;
    security_question2?: string;
    Contact: {
      first_name: string;
      last_name: string;
      email?: string;
      phone?: string;
      type: string;
      Location: {
        address1: string;
        address2?: string;
        city: string;
        state: string;
        zip: string;
        country?: string;
        lat?: string;
        lon?: string;
        elevation?: string;
        uri?: string;
      };
    };
  };
  account: {
    account_number: string;
    name?: string;
  };
  device_serial_number: string;
}

const createAccount = async (body: AccountCreateBody) => {
  const response = await fetcher(`${endpoints.accountCreation}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    if (response.status === 409) {
      const account = await response.json();
      return {
        ...account,
        message: "Failed to setup account",
      };
    }
    throw new Error();
  }

  return await response.json();
};

export const useCreateAccount = (
  options?: UseMutationOptions<AccountQueryList, Error, AccountCreateBody>
) => useMutation((body: AccountCreateBody) => createAccount(body), options);
