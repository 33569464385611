import { Box, Stack, Typography } from "@mui/material";
import Label from "../../components/Label";
import Input from "../../components/Input";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IActionsButtons } from "../../utils/interfaces/IActionsButtons";
import { Controller, useForm } from "react-hook-form";
import { Step3Type } from "../AccountSettings/AccountSettings";
import Select from "../../components/Select";
import SelectOption from "../../components/SelectOption/SelectOption";
import { useGetSecurityQuestions } from "../../hooks/useGetSecurityQuestions";
import sendErrorToast from "../../utils/sendErrorToast";
import Button from "../../components/Button";
import getREMFromPX from "../../utils/getREMFromPX";
import { SecurityQuestionsStep } from "./CreateAccount";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import Loader from "../../components/Loader";

interface AccountSecurityProps extends IActionsButtons {
  previousValues: SecurityQuestionsStep;
  handlePreviousStep?: () => void;
}

const AccountSecurity = ({
  previousValues,
  handleNextStep,
  handlePreviousStep,
  isLoading,
}: AccountSecurityProps) => {
  const {
    watch,
    register,
    control,
    formState: { isValid },
  } = useForm<Step3Type>({
    mode: "onChange",
    defaultValues: {
      question1Option: previousValues.question1Option,
      question2Option: previousValues.question2Option,
      question1Answer: previousValues.question1Answer,
      question2Answer: previousValues.question2Answer,
    },
  });

  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { data: securityQuestions, isLoading: isSecurityQuestionsLoading } =
    useGetSecurityQuestions({
      onError: () =>
        sendErrorToast(
          "There was an error fetching the security questions, please try again"
        ),
      refetchOnWindowFocus: false,
    });

  const question1Option = watch("question1Option");
  const question2Option = watch("question2Option");
  const question1Answer = watch("question1Answer");
  const question2Answer = watch("question2Answer");

  if (isSecurityQuestionsLoading) {
    return (
      <Box
        sx={{
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Stack flex={1} alignItems="center" width="100%" pl="30px" pr="30px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "flex-start",
          maxWidth: "110%",
          paddingTop: "1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: getREMFromPX(32),
            color: "#253331",
            fontFamily: "Inter",
            fontWeight: 600,
          }}
        >
          Account Security
        </Typography>
        <Typography
          sx={{
            fontSize: getREMFromPX(16),
            color: "#606060",
            marginTop: "1.3rem",
          }}
        >
          Please select two security questions below. These questions help us
          verify your identity should you not have access to your account
        </Typography>
        <Stack sx={{ marginTop: "2rem", width: "100%" }}>
          <Label htmlFor="question1Option">Question 1</Label>
          <Controller
            name="question1Option"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                placeholder="Select Question"
                {...field}
                id="question1Option"
              >
                {securityQuestions?.data.securityquestion
                  .filter(({ id }) => id !== question2Option)
                  .map(({ question, id }) => (
                    <SelectOption data-testid={question} key={id} value={id}>
                      {question}
                    </SelectOption>
                  ))}
              </Select>
            )}
          />
        </Stack>
        <Stack sx={{ marginTop: "20px", width: "100%" }}>
          <Input
            data-testid="answer1"
            placeholder="Answer"
            {...register("question1Answer", { required: true })}
          />
        </Stack>
        <Stack sx={{ marginTop: "4rem", width: "100%" }}>
          <Label htmlFor="question2Option">Question 2</Label>
          <Controller
            name="question2Option"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                placeholder="Select Question"
                {...field}
                id="question2Option"
              >
                {securityQuestions?.data.securityquestion
                  .filter(({ id }) => id !== question1Option)
                  .map(({ question, id }) => (
                    <SelectOption data-testid={question} key={id} value={id}>
                      {question}
                    </SelectOption>
                  ))}
              </Select>
            )}
          />
        </Stack>
        <Stack sx={{ marginTop: "20px", width: "100%" }}>
          <Input
            data-testid="answer2"
            placeholder="Answer"
            {...register("question2Answer", { required: true })}
          />
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "2rem",
            width: "100%",
          }}
        >
          {handlePreviousStep ? (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                color: colors.textPrimary.headingAlt,
                fontSize: getREMFromPX(16),
                cursor: "pointer",
              }}
            >
              <ArrowBackIosNewOutlinedIcon
                fill={colors.icons.default.fillColor}
              />
              <Typography onClick={handlePreviousStep}>Back</Typography>
            </div>
          ) : null}
          <Button
            text="Next"
            mode="default"
            disabled={!isValid || isLoading}
            isLoading={isLoading}
            sx={{ width: "218px", ml: "auto" }}
            onClick={() =>
              handleNextStep(
                {
                  stepName: "securityQuestionsStep",
                  question1Answer,
                  question1Option,
                  question2Answer,
                  question2Option,
                },
                true
              )
            }
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default AccountSecurity;
