import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import sendErrorToast from "../../utils/sendErrorToast";
import { useLogoutUser } from "../../hooks/useLogoutUser";
import { toast } from "react-toastify";

export type AuthenticationContext = {
  isAuthenticated: null | boolean;
  logOut: () => void;
};

export const AuthContext = React.createContext<AuthenticationContext | null>(
  null
);

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const { pathname } = location;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [showToast, setShowToast] = useState(false);
  const hasLoggedRef = useRef(false);
  const { mutateAsync: logoutUser } = useLogoutUser();

  const logOut = useCallback(async () => {
    try {
      toast.loading("Logging out...");
      await logoutUser();
      localStorage.removeItem("token");
      Cookies.remove("jwt");
      localStorage.clear();
      hasLoggedRef.current = false;
      setIsAuthenticated(false);
    } catch (error) {
      toast.error("An error occurred while logging out, Please try again!");
    } finally {
      toast.dismiss();
    }
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated,
      logOut,
    }),
    [isAuthenticated, logOut]
  );

  useEffect(() => {
    if (showToast) {
      sendErrorToast("Your session has expired, please log in again");
      setShowToast(false);
    }
  }, [showToast]);

  useEffect(() => {
    if (isAuthenticated === false && hasLoggedRef.current) {
      setShowToast(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || token === "undefined" || typeof token === "undefined") {
      setIsAuthenticated(false);
      return;
    }
    setIsAuthenticated(true);
    setShowToast(false);
    hasLoggedRef.current = true;
  }, [pathname]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
