import { MouseEventHandler, ReactNode, useContext, useState } from "react";
import {
  Box,
  Hidden,
  Stack,
  Toolbar,
  Typography,
  styled,
  Button,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import { NavBar } from "./NavBar";
import { routes } from "../../App";
import { UserProfileIcon } from "../../assets/icons";
import {
  WaffleIcon,
  BillingIcon,
  ServicesIcon,
  HelpIcon,
} from "../../assets/icons";
import useMenuAnchor from "../../hooks/useMenuAnchor";
import MenuItem from "../../components/MenuItem";
import Menu from "../../components/Menu";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { AuthContext, AuthenticationContext } from "../AuthProvider";
import { useGetConfig } from "../../hooks/useGetConfig";
import EntryPointLink from "../EntryPointLink/EntryPointLink";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";
import { Check, Logout, PersonOutline } from "@mui/icons-material";
import React from "react";
import CityIcon from "../CityIcon";

export interface MenuItemsFields {
  title: string;
  icon: any;
  route: any;
}

const menuListProps = {
  "aria-labelledby": "profile_options_button",
  sx: {
    padding: 0,
  },
};

const ParentContainer = styled("aside")<{ backgroundColor: string }>`
  background-color: ${(props) =>
    props.backgroundColor}; /* This is your fallback color */
  position: relative;
  min-height: 100vh;
`;

const LeftMenuGradient = styled(Stack)<{ backgroundColor: string }>`
  position: relative;
  height: 100%;
  background: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: row;
`;

export interface NavContainerProps<T> {
  title: string;
  headerContent?: ReactNode;
  children?: ReactNode;
  options?: Array<T>;
  eventHandler?: (val?: any) => void;
}

export function TabOptionButton({
  eventHandler,
  idx,
  option,
}: {
  idx: number;
  option: any;
  eventHandler: (idx: number) => void;
}) {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  return (
    <Button
      variant="text"
      onClick={() => eventHandler(idx)}
      sx={{
        fontSize: getREMFromPX(16),
        fontWeight: option.isActive ? "bold" : 600,
        fontFamily: "Inter",
        color: option.isActive
          ? colors.textPrimary.heading
          : colors.form.primary,
        textTransform: "none",
        "&:hover": {
          color: colors.form.primary,
          backgroundColor: "transparent",
        },
        cursor: "pointer",
        paddingLeft: 0,
        paddingRight: 0,
        height: "24px",
        borderRadius: "4px",
        background: "transparent",
        padding: "14px 10px",
        border: option.isActive
          ? `2px solid ${colors.border.secondary}`
          : `2px solid tranasperant`,
      }}
      data-cy={`navButton${option?.label.replace(/\s+/g, "")}`}
      aria-pressed={option.isActive}
    >
      {option?.label}
    </Button>
  );
}

const NavContainer = <T,>({
  title,
  children,
  options = [],
  eventHandler = () => {},
  headerContent,
}: NavContainerProps<T>) => {
  const { userInfo, setCurrentAccount, currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { logOut } = useContext(AuthContext) as AuthenticationContext;
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const { isMobile } = useViewport(862);

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const menuItems: Array<MenuItemsFields> = [
    {
      title: "Dashboard",
      icon: <WaffleIcon fill={colors.icons.default.fillColor} />,
      route: routes.dashboard,
    },
    ...(showBilling
      ? [
          {
            title: "Billing",
            icon: <BillingIcon fill={colors.icons.default.fillColor} />,
            route: routes.billing,
          },
        ]
      : []),
    {
      title: "Services",
      icon: <ServicesIcon fill={colors.icons.default.fillColor} />,
      route: routes.services,
    },
    {
      title: "Help",
      icon: <HelpIcon fill={colors.icons.default.fillColor} />,
      route: routes.help,
    },
  ];

  const menuOptions = [
    {
      label: "Account Settings",
      href: routes.profile.path,
      icon: (
        <PersonOutline
          sx={{ height: "28px", width: "28px", marginRight: "8px" }}
          fill={colors.icons.default.fillColor}
        />
      ),
    },
    {
      label: "Log out",
      href: "#",
      onClick: () => logOut(),
      icon: (
        <Logout
          sx={{
            height: "24px",
            width: "24px",
            marginRight: "8px",
            marginLeft: "4px",
          }}
          fill={colors.icons.default.fillColor}
        />
      ),
    },
  ];

  const {
    anchorEl: profileMenuAnchorEl,
    open: profileMenuOpen,
    handleClick: handleProfileMenuClick,
    handleClose: handleProfileMenuClose,
  } = useMenuAnchor();

  const handleAccountMenuClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAccountMenuOpen(true);
    handleProfileMenuClick(event);
  };
  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
    handleProfileMenuClose();
  };

  const drawer = (
    <ParentContainer backgroundColor={colors.backgrounds.fallback}>
      <LeftMenuGradient backgroundColor={colors.backgrounds.asideGradient}>
        <NavBar title={title} menuItems={menuItems} />
        <Stack
          aria-hidden="true"
          flexDirection="row"
          bgcolor={colors.backgrounds.default}
          padding={"1.5rem"}
          flex={1}
          style={{ borderTopLeftRadius: 50 }}
        ></Stack>
      </LeftMenuGradient>
    </ParentContainer>
  );

  const accounts = userInfo?.data?.user?.Accounts?.sort((_a, _b) => {
    const a = _a?.Devices?.at(0)?.Physicallocation;
    const b = _b?.Devices?.at(0)?.Physicallocation;

    if (!a || !b) return 0;

    const streetComparison = a.street.localeCompare(b.street);
    if (streetComparison !== 0) return streetComparison;

    const predirComparison = (a.pre_directional || "").localeCompare(
      b.pre_directional || ""
    );
    if (predirComparison !== 0) return predirComparison;

    const postdirComparison = (a.post_directional || "").localeCompare(
      b.post_directional || ""
    );
    if (postdirComparison !== 0) return postdirComparison;

    const houseComparison =
      parseInt(a.house_number, 10) - parseInt(b.house_number, 10);
    if (houseComparison !== 0) return houseComparison;

    return (a.apartment_number || "").localeCompare(b.apartment_number || "");
  });

  function LayoutBanner() {
    return (
      <Stack sx={{ width: "100%" }}>
        {!!headerContent ? (
          headerContent
        ) : (
          <Stack
            sx={{
              alignItems: "center",
              gap: 2,
              flexWrap: {
                md: "nowrap",
                xs: "wrap",
              },
            }}
            flexDirection="row"
          >
            <Typography
              variant="h2"
              fontFamily="Inter"
              fontSize={isMobile ? "1.5rem" : "2.5rem"}
              fontWeight="500"
              fontStyle="normal"
              color={colors.textPrimary.heading}
              sx={{
                verticalAlign: "center",
                padding: 0,
              }}
            >
              {title}
            </Typography>
            {options?.length > 0 && !isMobile && (
              <Box
                display="flex"
                gap={1.5}
                alignItems="center"
                mt="8px"
                width="100%"
              >
                {options.map((opt: any, idx) => (
                  <TabOptionButton
                    key={idx}
                    idx={idx}
                    option={opt}
                    eventHandler={eventHandler}
                  />
                ))}
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    );
  }
  function renderSwitchAccountsListView() {
    if (accounts && accounts.length > 1) {
      const accountChoices: JSX.Element[] = [];

      accounts.forEach((account) => {
        if (account) {
          const isSelected =
            currentAccount?.name === account.name ||
            currentAccount?.account_number === account?.account_number;

          accountChoices.push(
            <li key={account.id}>
              <MenuItem
                component={Link}
                to="#"
                key={account.id}
                onClick={() => {
                  handleAccountMenuClose();
                  setCurrentAccount(account);
                }}
                sx={{
                  minWidth: "264px",
                  borderRadius: 0,
                  margin: 0,
                  bgcolor: isSelected ? "#F0F0F0" : undefined,
                }}
                aria-label={`Switch account to ${
                  account?.name || account?.account_number
                }`}
              >
                <Box component="span" sx={{ textAlign: "left", width: "100%" }}>
                  {account?.name || account?.account_number}
                </Box>
                {isSelected ? (
                  <Check
                    sx={{
                      fontSize: "1.25rem",
                      color: colors.icons.default.secondaryFillColor,
                      strokeWidth: "1rem",
                    }}
                  />
                ) : null}
              </MenuItem>
            </li>
          );
        }
      });

      return accountChoices;
    }

    return null;
  }

  return (
    <Box display="flex">
      <Hidden mdDown>{drawer}</Hidden>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          paddingTop: isMobile ? "0rem" : "3.125rem",
          paddingLeft: 2,
          bgcolor: colors.backgrounds.default,
          position: "static",
          elevation: 0,
          width: "100%",
          maxWidth: "1800px",
        }}
      >
        <Toolbar
          component="header"
          sx={{
            alignItems: "center",
            justifyContent: {
              xs: "space-between",
              md: "start",
            },
            padding: {
              xs: 2,
            },
          }}
        >
          <Drawer
            open={isMobileDrawerOpen}
            onClose={() => setIsMobileDrawerOpen(false)}
            id="mobile-menu"
            sx={{ zIndex: 10000 }}
            PaperProps={{
              component: "nav",
              sx: {
                background: `linear-gradient(180deg, #CDEFD4 0%, #7BB8B0 100%)`,
                paddingTop: 1,
                gap: "4px",
              },
            }}
            component={"aside"}
          >
            {menuItems.map(({ title: optionTitle, icon, route }) => {
              const isActive = title === optionTitle;
              return (
                <Box
                  sx={{
                    width: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: "12px",
                    fontWeight: isActive ? "bold" : "500",
                    fontFamily: "Inter",
                    color: isActive
                      ? colors.table.pagination.primary
                      : colors.textPrimary.heading,
                    background: isActive
                      ? colors.backgrounds.default
                      : "transparent",
                    margin: "0px 0px 0px 10px",
                    borderRadius: "8px 0px 0px 8px",
                    padding: "8px 10px 8px 10px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  component={Link}
                  to={route.path}
                  key={optionTitle}
                >
                  {React.cloneElement(icon, {
                    height: "18px",
                    width: "18px",
                  })}
                  {optionTitle}
                </Box>
              );
            })}
          </Drawer>
          {isMobile ? (
            <MenuIcon
              style={{
                borderRadius: "30%",
                cursor: "pointer",
                width: "2.5rem",
                height: "2.5rem",
              }}
              fill={colors.icons.default.fillColor}
              onClick={() => setIsMobileDrawerOpen(true)}
            />
          ) : null}
          {isMobile ? <CityIcon /> : <LayoutBanner />}
          <Box
            sx={{
              textAlign: "right",
              marginRight: isMobile ? 0 : 4,
            }}
          >
            <Box
              sx={{
                whiteSpace: "nowrap",
                display: "flex",
                cursor: "pointer",
              }}
            >
              {!isMobile ? (
                <Box
                  sx={{
                    color: "black",
                    margin: "auto",
                    paddingRight: "1rem",
                  }}
                >
                  <Box component="span" onClick={handleProfileMenuClick}>
                    {currentAccount?.name || currentAccount?.account_number}
                  </Box>
                  {accounts && accounts.length > 1 && (
                    <Box
                      sx={{
                        background: "#fff",
                        border: `1px solid ${colors.buttons.outline.border}`,
                        fontSize: 14,
                        fontWeight: 500,
                        color: colors.form.primary,
                        textAlign: "center",
                        borderRadius: "4px",
                        width: "fit-content",
                        marginLeft: "auto",
                        padding: "2px 16px",
                        fontFamily: "Inter",
                      }}
                      onClick={handleAccountMenuClick}
                      data-testid="switch-accounts-btn"
                    >
                      Switch Accounts
                    </Box>
                  )}
                </Box>
              ) : null}
              <UserProfileIcon
                onClick={handleProfileMenuClick}
                style={{
                  borderRadius: "30%",
                  cursor: "pointer",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
                fill={colors.icons.default.fillColor}
                data-cy="navcontainer-user-icon"
                data-testid="navcontainer-user-icon"
              />
            </Box>
            <Hidden mdUp>
              <Menu
                id="profile-menu"
                anchorEl={profileMenuAnchorEl}
                open={profileMenuOpen && !accountMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={menuListProps}
              >
                {menuOptions.map(({ label, onClick, href, icon }) => (
                  <li
                    key={label}
                    onClick={() => {
                      handleProfileMenuClose();
                      onClick?.();
                    }}
                  >
                    <MenuItem component={Link} to={href}>
                      {icon}
                      {label}
                    </MenuItem>
                  </li>
                ))}
                {renderSwitchAccountsListView()}
              </Menu>
            </Hidden>

            <Hidden mdDown>
              <Menu
                id="profile-menu"
                anchorEl={profileMenuAnchorEl}
                open={profileMenuOpen && !accountMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={menuListProps}
              >
                {menuOptions.map(({ label, onClick, href, icon }) => (
                  <li
                    key={label}
                    onClick={() => {
                      handleProfileMenuClose();
                      onClick?.();
                    }}
                  >
                    <MenuItem component={Link} to={href}>
                      {icon}
                      {label}
                    </MenuItem>
                  </li>
                ))}
              </Menu>
            </Hidden>

            <Menu
              id="profile-menu"
              anchorEl={profileMenuAnchorEl}
              open={profileMenuOpen && accountMenuOpen}
              onClose={handleAccountMenuClose}
              MenuListProps={menuListProps}
              sx={{
                padding: 0,
              }}
              slotProps={{
                paper: {
                  sx: {
                    padding: 0,
                    marginTop: "10px",
                    border: "1px solid #E0E0E0",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {renderSwitchAccountsListView()}
            </Menu>
          </Box>
        </Toolbar>
        {isMobile ? (
          <Box sx={{ px: 2 }}>
            <LayoutBanner />
          </Box>
        ) : null}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: {
              md: 3,
              xs: 2,
            },
            bgcolor: colors.backgrounds.default,
            color: "black",
            paddingBottom: "4.5rem",
          }}
        >
          {children}
          <Box sx={{ marginTop: "2.25rem" }}>
            <EntryPointLink />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NavContainer;
