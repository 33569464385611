import { useState, useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Stack, Typography, InputAdornment, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileSettingsModal } from "../../../components/CustomModal";
import { useChangePassword } from "../../../hooks/useChangePassword";
import useGetUserId from "../../../hooks/useGetUserId";
import Button from "../../../components/Button";
import Label from "../../../components/Label";
import Input from "../../../components/Input/Input";
import getREMFromPX from "../../../utils/getREMFromPX";
import sendErrorToast from "../../../utils/sendErrorToast";
import isValidPassword from "../../../utils/isValidPassword";
import { theme } from "../../../theme";
import { OnboardingBubble } from "../../../assets/icons";
import { useLoginUser } from "../../../hooks/useLoginUser";
import PasswordValidation from "../../../components/Validation/Password";
import ConfirmPasswordValidation from "../../../components/Validation/ConfirmPassword";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../components/AddressAndAccountProvider";
import {
  iconStyle,
  inputAdornmentStyle,
  iconStyleAdornment,
  iconStylePad,
} from "./Styles";
import useGetThemePath from "../../../hooks/useGetThemePath";

export type PasswordType = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const EditPassword = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const userId = useGetUserId();

  const [content, setContent] = useState("old");
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PasswordType>({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { data, mutate: loginUser, isLoading } = useLoginUser();

  const currentPassword = watch("currentPassword").trim();
  const newPassword = watch("newPassword").trim();
  const confirmPassword = watch("confirmPassword").trim();

  const { mutateAsync: changePassword, isLoading: isLoadingNewPassword } =
    useChangePassword(userId, {
      onSuccess: () => {
        setContent("success");
      },
      onError: () =>
        sendErrorToast(
          "There was a problem changing the password, please try again"
        ),
    });

  const onSubmitHandler = useCallback(
    async (e: PasswordType) => {
      if (
        userInfo?.data?.user?.username &&
        e.currentPassword &&
        e.newPassword === e.confirmPassword
      ) {
        loginUser({
          userName: userInfo?.data?.user?.username,
          password: e.currentPassword,
        });
        closeModal();
      }
    },
    [userInfo, loginUser]
  );

  useEffect(() => {
    if (data === "Invalid Email or Username / Password combination.") {
      sendErrorToast("Error saving new password");
    }
    if (
      data !== "Invalid Email or Username / Password combination." &&
      data?.data?.user?.jwt &&
      newPassword === confirmPassword
    ) {
      changePassword({ newPassword, confirmPassword });
    }
  }, [changePassword, newPassword, confirmPassword, data]);

  const disableButton =
    isLoadingNewPassword ||
    isLoading ||
    !isValid ||
    !!Object.values(errors).length ||
    newPassword !== confirmPassword;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const passwordsMatch = !confirmPassword || confirmPassword === newPassword;

  const closeModal = () => {
    setIsEditing(false);
    setContent("old");
  };

  const getContent = () => {
    switch (content) {
      case "success":
        return (
          <Stack sx={{ alignItems: "center" }}>
            <OnboardingBubble />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: getREMFromPX(20),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: "#000000",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              Your password has been updated
            </Typography>
            <Button
              text="OK"
              mode="default"
              disabled={!isValid}
              isLoading={isLoadingNewPassword}
              sx={{ width: "218px" }}
              onClick={() => {
                closeModal();
              }}
            />
          </Stack>
        );
      default:
        return (
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            data-testid="edit-username"
          >
            <Typography
              id="passwordExplanation"
              sx={{
                fontFamily: "Inter",
                fontSize: getREMFromPX(14),
                fontWeight: "500",
                marginBottom: getREMFromPX(theme.spacing * 4),
                maxWidth: "440px",
                mt: {
                  md: 0,
                  xs: 6,
                },
              }}
            >
              Please enter your current password, followed by your new password
              and a confirmation of the new password.
            </Typography>
            <Stack
              spacing={getREMFromPX(theme.spacing * 2)}
              direction="column"
              mb={getREMFromPX(theme.spacing * 4)}
            >
              <Label
                htmlFor="currentPassword"
                aria-required="true"
                id="currentPasswordLabel"
              >
                Current Password
              </Label>
              <Input
                id="currentPassword"
                data-testid="currentPassword"
                type={showCurrentPassword ? "text" : "password"}
                autoComplete="current-password"
                placeholder="Enter your current password"
                aria-describedby="passwordExplanation"
                aria-labelledby="currentPasswordLabel"
                style={inputAdornmentStyle}
                error={!!errors.currentPassword && !!currentPassword.length}
                softError={!!errors.currentPassword || !currentPassword.length}
                endAdornment={
                  <InputAdornment position="end" style={iconStyleAdornment}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      style={iconStylePad}
                      data-cy="editpassword-togglepasswordview"
                    >
                      {showCurrentPassword ? (
                        <FontAwesomeIcon
                          icon="eye-slash"
                          style={iconStyle}
                          fill={colors.icons.default.fillColor}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="eye"
                          style={iconStyle}
                          fill={colors.icons.default.fillColor}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                {...register("currentPassword", {
                  required: true,
                })}
                aria-required="true"
              />
            </Stack>
            <Stack spacing={getREMFromPX(theme.spacing * 2)} direction="column">
              <Label
                htmlFor="newPassword"
                aria-required="true"
                id="newPasswordLabel"
              >
                New Password
              </Label>
              <Input
                id="newPassword"
                data-testid="newPassword"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                placeholder="Enter your new password"
                error={
                  (!!errors.newPassword && !!newPassword.length) ||
                  !passwordsMatch
                }
                softError={
                  !!errors.newPassword || !newPassword.length || !passwordsMatch
                }
                aria-describedby="passwordRequirements"
                aria-labelledby="newPasswordLabel"
                style={inputAdornmentStyle}
                endAdornment={
                  <InputAdornment position="end" style={iconStyleAdornment}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      style={iconStylePad}
                      data-cy="editpassword-confirm-togglepasswordview"
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon="eye-slash"
                          style={iconStyle}
                          fill={colors.icons.default.fillColor}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="eye"
                          style={iconStyle}
                          fill={colors.icons.default.fillColor}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                {...register("newPassword", {
                  required: true,
                  validate: (value) =>
                    isValidPassword(value) || "Invalid password",
                })}
                aria-required="true"
              />
            </Stack>
            <PasswordValidation password={newPassword} />
            <Stack
              spacing={getREMFromPX(theme.spacing * 2)}
              direction="column"
              mt={getREMFromPX(theme.spacing * 4)}
            >
              <Label
                htmlFor="confirmPassword"
                id="confirmPasswordLabel"
                aria-required="true"
              >
                Confirm Password
              </Label>
              <Input
                id="confirmPassword"
                data-testid="confirmPassword"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                placeholder="Confirm your new password"
                aria-describedby="confirmPasswordRequirements"
                aria-labelledby="confirmPasswordLabel"
                error={
                  (!!errors.confirmPassword && !!confirmPassword.length) ||
                  !passwordsMatch
                }
                softError={
                  !!errors.confirmPassword ||
                  !confirmPassword.length ||
                  !passwordsMatch
                }
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) =>
                    value === newPassword || "Passwords do not match",
                })}
                aria-required="true"
              />
              <ConfirmPasswordValidation
                confirmPassword={confirmPassword}
                passwordsMatch={passwordsMatch}
              />
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              paddingTop={getREMFromPX(theme.spacing * 8)}
            >
              <Button
                data-testid="saveButton"
                mode="default"
                text="Save"
                type="submit"
                size="large"
                disabled={disableButton}
                sx={{ width: 220 }}
              />
            </Stack>
          </form>
        );
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
      <ProfileSettingsModal onClose={closeModal} open={isEditing}>
        {content !== "success" && (
          <>
            <Typography
              sx={{
                position: "absolute",
                top: {
                  md: 30,
                  xs: 14,
                },
                fontFamily: "Inter",
                fontSize: getREMFromPX(20),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.textPrimary.heading,
                height: 40,
                width: "100%",
                backgroundColor: colors.white || "#ffffff",
              }}
            >
              Choose a new password
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: {
                  md: 15,
                  xs: 6,
                },
                right: 15,
                color: colors.links.teal,
              }}
              onClick={() => closeModal()}
              data-cy="editpassword-close"
            >
              <CloseIcon
                sx={{ fontWeight: "bolder" }}
                fontSize="large"
                fill={colors.icons.default.fillColor}
              />
            </IconButton>
          </>
        )}
        {getContent()}
      </ProfileSettingsModal>
      <Typography
        sx={{
          flex: 1,
          fontFamily: "Inter",
          fontSize: {
            md: getREMFromPX(16),
            xs: getREMFromPX(14),
          },
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
        }}
      >
        Password
      </Typography>
      <Typography
        sx={{
          flex: 1.5,
          fontFamily: "Inter",
          fontSize: getREMFromPX(16),
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
        }}
      >
        •••••••
      </Typography>
      <Typography
        onClick={() => setIsEditing(true)}
        sx={{
          cursor: "pointer",
          fontFamily: "Inter",
          fontSize: getREMFromPX(16),
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          color: colors.links.teal,
        }}
        data-cy="editpassword-edit"
      >
        Edit
      </Typography>
    </Stack>
  );
};

export default EditPassword;
