import validateEmail from "../utils/validateEmail";
import { useGetProvider } from "./useGetProvider";

export default function useSupportEmail() {
  const { data: provider, isLoading } = useGetProvider();
  const defaultSupportEmail = "help@entpnt.com";

  const currentProviderSupportEmail = provider?.data?.provider?.Contacts?.find(
    (contact) => contact.type === "SUPPORT"
  )?.email;

  if (isLoading) {
    return undefined;
  }

  if (
    currentProviderSupportEmail &&
    validateEmail(currentProviderSupportEmail)
  ) {
    return currentProviderSupportEmail;
  }

  return defaultSupportEmail;
}
